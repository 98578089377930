import { Paper, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { ServerHelper } from '../../utilities/ServerHelper';

import styles from './RegistrationHeader.module.css';
import { RegistrationHeaderStyles } from './RegistrationHeaderStyles';

interface Props {
  error: boolean
}

export default function RegistrationHeader(props: Props): ReactElement {

  const classes = RegistrationHeaderStyles();
  
  return (
      <div
          className={
              props.error
                  ? `${styles.registrationHeaderWrapper} ${styles.registrationHeaderWrapperError}`
                  : `${styles.registrationHeaderWrapper}`
          }
      >
          <Paper elevation={0} classes={{ root: classes.logoHolder }}>
              <img
                  className={styles.showboatLogo}
                  src={ServerHelper.ApplicationSkin.landingPageThumbnailURL}
                  alt="logo"
              />
          </Paper>

          <Typography variant="h2" classes={{ root: classes.eventWelcome }}>
              Register for
          </Typography>

          <Typography
              variant="h1"
              classes={{
                  root: props.error
                      ? `${classes.eventName} ${classes.eventNameError}`
                      : `${classes.eventName}`,
              }}
          >
              {ServerHelper.ApplicationSkin.eventName}
          </Typography>
      </div>
  );
}
