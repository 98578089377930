import { Button, Divider, Link, Paper, Typography } from "@material-ui/core";
import React, { ReactElement, useState, useEffect, useRef } from "react";
import Linkify from "react-linkify";
import isEmail from "validator/lib/isEmail";
import DateIcon from "@material-ui/icons/Event";

import "../App.css";
import { ServerHelper } from "../utilities/ServerHelper";
import RegistrationForm from "./RegistrationForm/RegistrationForm";
import RegistrationHeader from "./RegistrationHeader/RegistrationHeader";
import RegistrationDetails from "./RegistrationDetails/RegistrationDetails";

import { RegistrationPageStyles } from "./RegistrationPageStyles";
import styles from "./RegistrationPage.module.css";

interface Props {}

export default function RegistrationPage(props: Props): ReactElement {
  const [linkErrorMessage, setLinkErrorMessage] = useState("");

  const [formHasBeenCompleted, toggleFormHasBeenCompleted] = useState(false);

  const [loginCode, setLoginCode] = useState("");

  const firstRender = useRef(true);

  useEffect(() => {
    if (ServerHelper.deadLoginCode) {
      setLinkErrorMessage(ServerHelper.errorMsg);
    }
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    let strSplit = linkErrorMessage.split(" ");

    for (let i = 0; i < strSplit.length; i++) {
      if (isEmail(strSplit[i])) {
        strSplit[i] = `<EMAIL>${strSplit[i]}</EMAIL>`;
      }
    }

    setLinkErrorMessage(strSplit.join(" "));
  }, [linkErrorMessage]);

  /* FORM COMPLETE TO SHOW CONFIRMATION */
  const handleFormComplete = (loginCode: string) => {
    setLoginCode(loginCode);
    toggleFormHasBeenCompleted(true);
  };
  /**/

  const handleAddToCalendarClick = () => {
    //Formulate call to calendar endpoint

    let replyTo = ServerHelper.replyToEmailAddress;

    window.open(
      `${ServerHelper.appAPIUrl}/calendar?loginCode=${loginCode}&replyTo=${replyTo}`
    );
  };

  //Copy intake link
  const handleCopyIntakeLink = () => {
    let dummy = document.createElement("textarea");

    document.body.appendChild(dummy);

    dummy.value = `https://${document.getElementById("intakeLink").innerText}`;
    dummy.select();

    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  const classes = RegistrationPageStyles();

  let body;

  if (ServerHelper.loginFailReason === "DeletedLoginCode") {
    body = (
      <React.Fragment>
        <div className={styles.registrationMainHolder}>
          <RegistrationHeader error={true} />

          <Paper className={classes.deadLinkErrorHolder}>
            <Typography variant="body1" className={classes.deadLinkError}>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    target="blank"
                    href={decoratedHref}
                    key={key}
                    style={{ color: "#FFB700", textDecoration: "none" }}
                  >
                    {decoratedText}
                  </a>
                )}
              >
                {ServerHelper.errorMsg}
              </Linkify>
            </Typography>
          </Paper>
        </div>
      </React.Fragment>
    );
  } else if (ServerHelper.loginFailReason === "Ended") {
    body = (
      <React.Fragment>
        <div className={styles.registrationMainHolder}>
          <RegistrationHeader error={true} />

          <Paper className={classes.deadLinkErrorHolder}>
            <Typography variant="body1" className={classes.deadLinkError}>
              This event has ended.
            </Typography>
          </Paper>
        </div>
      </React.Fragment>
    );
  } else if (ServerHelper.loginFailReason === "RegistrationCapacityFull") {
    body = (
      <React.Fragment>
        <div className={styles.registrationMainHolder}>
          <RegistrationHeader error={true} />

          <Paper className={classes.capacityFullErrorHolder}>
            <Typography variant="body1" className={classes.capacityFullError}>
              Capacity for this event has been reached.
            </Typography>
          </Paper>
        </div>
      </React.Fragment>
    );
  } else {
    body = (
      <React.Fragment>
        <div className={styles.registrationMainHolder}>
          <RegistrationHeader error={false} />

          <RegistrationDetails />

          {formHasBeenCompleted ? (
            <Paper className={classes.confirmationHolder}>
              <Typography variant="body1" className={classes.allSetHeader}>
                Confirmed
              </Typography>

              <Typography
                variant="body1"
                className={classes.confirmationMessage}
              >
                Please add us to your calendar by clicking the button below.
              </Typography>

              <Button
                variant="contained"
                classes={{
                  root: classes.addToCalendarButton,
                  label: classes.addToCalendarButtonLabel,
                }}
                onClick={handleAddToCalendarClick}
              >
                <DateIcon className={classes.dateIcon} />
                ADD TO CALENDAR
              </Button>

              <Typography variant="body1" className={classes.eventLinkHeader}>
                Your personal event link:
              </Typography>

              <div className={styles.linkHolder}>
                <Link
                  className={classes.intakeLink}
                  target="_blank"
                  href={`https://go.showboat.live/${loginCode}`}
                >
                  <span id="intakeLink">go.showboat.live/{loginCode}</span>
                </Link>

                <img
                  src="assets/images/CopyIcon.png"
                  alt="copy icon"
                  onClick={handleCopyIntakeLink}
                  className={styles.copyIcon}
                />
              </div>

              <Divider className={classes.confirmDivider}></Divider>

              <Typography variant="body1" className={classes.emailMessage}>
                A calendar invite and personal event link has also been sent to
                your email address.
              </Typography>
            </Paper>
          ) : (
            <Paper className={classes.registrationHolder}>
              <Typography
                variant="h1"
                classes={{ root: classes.registerNowHeader }}
              >
                Register Now
              </Typography>

              <RegistrationForm handleFormComplete={handleFormComplete} />
            </Paper>
          )}
        </div>
      </React.Fragment>
    );
  }

  return (
    <Paper square className={classes.registrationWrapper}>
      {body}
    </Paper>
  );
}
