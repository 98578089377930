import React, { ReactElement } from 'react';
import { Typography, Paper } from '@material-ui/core';

import styles from './TimeoutErrorPage.module.css';
import { TimeoutErrorPageStyles } from './TimeoutErrorPageStyles';

interface Props {
  
}

export default function TimeoutErrorPage(props: Props): ReactElement {

  const classes = TimeoutErrorPageStyles();
  
  return (
    <div className={styles.errorPageHolder}>
      <Paper className={classes.errorMessageHolder}>

        <img className={styles.showboatLogo} alt="showboat logo" src="assets/images/sb-logo.png" />
        
        <Typography variant="body1" className={classes.errorMessage}>
          An error occurred loading, please reload the page to try again.
        </Typography>
      </Paper>
    </div>
  )
}
