import { makeStyles, createStyles } from '@material-ui/core/styles';

const RegistrationFormStyles = makeStyles((theme: any) => createStyles({
  bioInput: {
    width: 460,
    height: 56,
    backgroundColor: theme.palette.intakeInput.main,
    marginBottom: 37,
    [theme.breakpoints.down('sm')]: {
      width: 330,
    },
    [theme.breakpoints.down('xs')]: {
      width: 425,
    },
    '@media (max-width: 529.95px)': {
      width: 325,
    },
    '@media (max-width: 419.95px)': {
      width: 295,
    },
    '@media (max-width: 384.95px)': {
      width: 230,
    }
  },
  bioNext: {
    backgroundColor: theme.palette.intakeBlue.main,
    color: "white",
    height: 46,
    width: 115,
    position: "absolute",
    right: 50,
    bottom: 54,
    [theme.breakpoints.down('sm')]: {
      right: 35,
    },
    [theme.breakpoints.down('xs')]: {
      right: 25,
    }
  },
  bioInputNotRequired: {
    width: 460,
    height: 56,
    backgroundColor: theme.palette.intakeInput.main,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      width: 330,
    },
    [theme.breakpoints.down('xs')]: {
      width: 425,
    },
    '@media (max-width: 529.95px)': {
      width: 325,
    },
    '@media (max-width: 419.95px)': {
      width: 295,
    },
    '@media (max-width: 384.95px)': {
      width: 230,
    }
  },
  errorText: {
    fontSize: 12
  },
  input: {
    height: 460,
    display: "inline-block",
  },
  loadingSpinner: {
    color: "grey",
    height: "30px !important",
    width: "30px !important",
    position: "absolute",
    right: 185,
    bottom: 63,
    [theme.breakpoints.down('sm')]: {
      right: 175,
    },
    [theme.breakpoints.down('xs')]: {
      right: 165,
    }
  },
  allSetHeader: {
    fontSize: 24,
    position: "absolute",
    top: 39,
    left: 50,
  },
  confirmationMessage: { 
    fontSize: 24,
    position: "absolute",
    left: 50,
    bottom: 68
  },
  error: {
    color: "red",
    fontSize: 14,
    position: "relative",
    left: 50,
    top: 25,
  }
})
)

export { RegistrationFormStyles };