import React, { ReactElement } from 'react';
import { Typography, Paper } from '@material-ui/core';

import styles from './FailedLoginErrorPage.module.css';
import { FailedLoginErrorPageStyles } from './FailedLoginErrorPageStyles';

interface Props {
  
}

export default function FailedLoginErrorPage(props: Props): ReactElement {

  const classes = FailedLoginErrorPageStyles();
  
  return (
    <div className={styles.errorPageWrapper}>
      <Paper className={classes.errorMessageHolder}>

        <img className={styles.showboatLogo} alt="showboat logo" src="assets/images/sb-logo.png" />

        <Typography variant="body1" className={classes.errorMessage}>
          An error occurred logging in.
        </Typography>
        
      </Paper>
    </div>
  )
}
