import React from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import RegistrationPage from './RegistrationPage/RegistrationPage';

export default function App(props) {

  /*Material UI themes */
  const DarkTheme = createMuiTheme({
    typography: {
      "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
    },
    breakpoints: {
      values: {
        phone: 0,
        xs: 535,
        sm: 730,
        md: 1000,
        lg: 1281,
      }
    },
    palette: {
      type: 'dark',
      primary: {
        main: props.primaryThemeColor
      },
      secondary: {
        main: "#25252B",
        dark: "#19191F",
      },
      intakeInput: {
        main: "#111112",
      }, 
      intakeBackground: {
        main: "#1B1B1D"
      }, 
      inputBackgroundColor: {
        main: "#3E4044"
      },
      intakePaper: {
        main: "black"
      },
      intakeBlue: {
        main: "#00B6FF"
      },
      selectedMode: {
        main: "#5c5c5c"
      },
      background: {
        paper: "#2F3136",
        default: "#1A1A1C",
      },
      emojiIcon: {
        main: "white",
      },
      toAttendeeSelect: {
        main: "#505050"
      }
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*': {
            'scrollbar-width': 'thin',
          },
          '*::-webkit-scrollbar': {
            width: '4px',
            height: '4px',
          }
        }
      }
    }
  });

  const LightTheme = createMuiTheme({
    typography: {
      "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
    },
    breakpoints: {
      values: {
        phone: 0,
        xs: 535,
        sm: 730,
        md: 1000,
        lg: 1281,
      }
    },
    palette: {
      type: 'light',
      primary: {
        main: props.primaryThemeColor
      },
      secondary: {
        main: "#fff",
        dark: "#f0f0f0"
      },
      intakeBlue: {
        main: "#00B6FF"
      },
      intakeBackground: {
        main: "#ededed",
      },
      inputBackgroundColor: {
        main: "white",
      },
      emojiIcon: {
        main: "grey"
      },
      selectedMode: {
        main: "#5c5c5c"
      },
      intakePaper: {
        main: "white",
      },
      toAttendeeSelect: {
        main: "#c9c9c9"
      },
      intakeInput: {

      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*': {
            'scrollbar-width': 'thin',
          },
          '*::-webkit-scrollbar': {
            width: '4px',
            height: '4px',
          }
        }
      }
    }
  })
  /**/

  return (
    <ThemeProvider theme={props.theme === "dark" ? DarkTheme : LightTheme}>
        <RegistrationPage />
    </ThemeProvider>
  )
}
