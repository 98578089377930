import { makeStyles, createStyles } from '@material-ui/core/styles';

const RegistrationDetailsStyles = makeStyles((theme: any) => createStyles({
  registrationDetailsHolder: {
    backgroundColor: theme.palette.intakePaper.main,
    position: "relative",
    float: "left",
    top: 75,
    width: 370,
    height: 589,
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      width: 280,
    },
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      height: 200,
      marginBottom: 40,
      top: 55,
    }
  },
  bookingDetailsHeader: {
    fontSize: 24,
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 400,
    position: "absolute",
    top: 31,
    left: 41,
    [theme.breakpoints.down('sm')]: {
      left: 30,
      fontSize: 22,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
      top: 25,
    },
    '@media (max-width: 529.95px)': {
      fontSize: 20,
    }

  },
  bookingDate: {
    fontSize: 20,
    position: "absolute",
    top: 100,
    left: 41,
    [theme.breakpoints.down('sm')]: {
      left: 30,
      fontSize: 18
    },
    [theme.breakpoints.down('xs')]: {
      top: 65,
    },
    '@media (max-width: 529.95px)': {
      fontSize: 17
    }
  },
  bookingTime: {
    fontSize: 20,
    position: "absolute",
    top: 135,
    left: 41,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      left: 30,
    },
    [theme.breakpoints.down('xs')]: {
      top: 100,
    },
    '@media (max-width: 529.95px)': {
      fontSize: 17,
    }
  },
  bookingDuration: {
    fontSize: 20,
    position: "absolute",
    top: 170,
    left: 41,
    textAlign: "left",
    [theme.breakpoints.down('sm')]: {
      left: 30,
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      top: 135,
    },
    '@media (max-width: 529.95px)': {
      fontSize: 17,
    }
  },
  changeLink: {
    color: "#00B6FF",
    position: "absolute",
    left: 41,
    top: 230
  },
  dateIcon: {
    color: "#6F6F6F",
    position: "relative",
    marginRight: 13,
    top: 5,
  },
  timeIcon: {
    color: "#6F6F6F",
    position: "relative",
    marginRight: 13,
    top: 5,
  },
  durationIcon: {
    color: "#6F6F6F",
    position: "relative",
    marginRight: 13,
    top: 5,
  },
  leftIcon: {
    position: "relative",
    top: 7,
    marginRight: 5,
  }
})
)

export { RegistrationDetailsStyles };